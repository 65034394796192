import { SvgIconMemo, SvgIconProps } from './svg-icon';

export function BulletArrowDownIcon({ viewBox = '0 0 12 12', ...restProps }: SvgIconProps) {
  return (
    <SvgIconMemo viewBox={viewBox} {...restProps}>
      <path
        d="M11.5832 3.6943C11.9956 3.1694 11.6217 2.40005 10.9541 2.40005H1.04607C0.378522 2.40005 0.00459003 3.1694 0.417013 3.69431L5.37104 9.99943C5.69135 10.4071 6.30885 10.4071 6.62915 9.99943L11.5832 3.6943Z"
        fill="currentColor"
      />
    </SvgIconMemo>
  );
}
