import { SvgIconMemo, SvgIconProps } from './svg-icon';

export function ChevronLeftIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIconMemo viewBox={viewBox} {...restProps}>
      <path
        d="M19.6688 27L21.4968 25.22L12.7985 16.75C12.5109 16.47 12.5109 16.02 12.7985 15.74L22 6.78L20.172 5L10.9705 13.96C9.67651 15.22 9.67651 17.27 10.9705 18.53L19.6688 27Z"
        fill="currentColor"
      />
    </SvgIconMemo>
  );
}
